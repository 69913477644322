<template>
	<div class="p-buycar">
		<div class="p-buycar-tips">全部课程({{mycarnum}})</div>
		<div class="p-buycar-head">
			<el-col :span="4">
				<div class="p-buycar-text">
					<div @click="tchooseall()" :class="['p-radio', chooseall==1 ? 'p-radio p-radio-c':'']"></div>
					<div>全选</div>
				</div>
			</el-col>
			<el-col :span="9">
				<div class="p-buycar-text">课程信息</div>
			</el-col>
			<el-col :span="7">
				<div class="p-buycar-text">单价（元）</div>
			</el-col>
			<el-col :span="4">
				<div class="p-buycar-text">操作</div>
			</el-col>
		</div>
		<div class="p-buycar-three" v-for="(item,index) in mycar" :key="index">
			<el-row :gutter="20">
				<div class="p--buycar-item-head">
					<el-col :span="4">
						<div style="display: flex;flex-wrap: wrap;align-items: flex-start;">
							<div @click="tochoosetwo(index,item.school_id)" :class="['p-radio', item.ischoose==1 ? 'p-radio p-radio-c':'']">
							</div>
							<img class="p-classabout" src="../../../assets/img/buycar/classabout.png" />
							<span class="p-classabout-span">{{item.school_name}} </span> <img class="p--buycar-more" src="../../../assets/img/buycar/more.png" />
						</div>
					</el-col>
				</div>

				<div v-for="(items,indexa) in item.goods" :key="indexa" :class="['p-buycar-three-item', indexa<(item.goods.length-1) ? 'p-buycar-three-item p-buycar-b':'']">
					<el-row :gutter="20">
						<el-col :span="4">
							<div class="p-buycar-text ml-2">
								<div @click="tochooseone(index,indexa,items.goods_id)" :class="['p-radio', items.ischoose==1 ? 'p-radio p-radio-c':'']">
								</div>
								<img class="p-buycar-image" :src="items.goods_img" />
							</div>
						</el-col>
						<el-col :span="9">
							<div class="p-buycar-text">{{items.goods_name}}</div>
						</el-col>
						<el-col :span="7">
							<div class="p-buycar-text">{{items.goods_price}}</div>
						</el-col>
						<el-col :span="4">
							<div class="p-buycar-delete" @click="delcart(items.id)">移除购物车</div>
						</el-col>
					</el-row>
				</div>
			</el-row>
		</div>
		<div class="p-buycar-bottom">
			<el-col :span="16">
				<div class="p-buycar-text l64">
					<div @click="tchooseall" :class="['p-radio', chooseall==1 ? 'p-radio p-radio-c':'']">
					</div>
					<div>全选</div>
				</div>
			</el-col>
			<el-col :span="4">
				<div class="p-buycar-price">
					<div class="">合计：<span>￥{{price}}</span></div>
					<div class="">优惠详情见支付页</div>
				</div>
			</el-col>
			<el-col :span="4">
				<div :class="['p-buycar-count', price>0 ? 'p-buycar-count p-buycar-count-c':'']" @click="checkout()">结算</div>
			</el-col>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		data() {
			return {
				mycar: [],
				radio: 0,
				click: true,
				clicka: true,
				chooseall: 0,
				clickb: false,
				array: [],
				price: 0,
				mycarnum: 0,
			};
		},
		created() {
			this.mycart()
		},
		methods: {
			tchooseall() {
				if (this.click) {
					this.chooseall = 1
					for (var i = 0; i < this.mycar.length; i++) {
						this.mycar[i].ischoose = 1
						for (var j = 0; j < this.mycar[i].goods.length; j++) {
							this.mycar[i].goods[j].ischoose = 1
							this.array.push(this.mycar[i].goods[j].goods_id)
						}
					}
					this.click = false
					this.clicka = true
					this.clickb = true
					this.calculate_cart()
				} else {
					this.chooseall = 0
					this.array = []
					this.click = true
					this.clicka = false
					this.clickb = false
					for (var i = 0; i < this.mycar.length; i++) {
						this.mycar[i].ischoose = 0
						for (var j = 0; j < this.mycar[i].goods.length; j++) {
							this.mycar[i].goods[j].ischoose = 0
						}
					}
					this.price = 0
				}
			},
			tochoosetwo(index, id) {
				var newproject = {}
				for (var i = 0; i < this.mycar.length; i++) {
					if (id == this.mycar[i].school_id) {
						if (this.mycar[index].ischoose == 1) {
							var arrayb = []
							for (var j = 0; j < this.mycar[index].goods.length; j++) {
								this.mycar[index].goods[j].ischoose = 0
								arrayb.push(this.mycar[index].goods[j].goods_id)
							}
							this.mycar[index].ischoose = 0
							this.clickb = false
							this.clicka = false
							this.mycar.push(newproject)
							this.mycar.pop()
							for (var d = 0; d < this.array.length; d++) {
								for (var l = 0; l < arrayb.length; l++) {
									if (this.array[d] == arrayb[l]) {
										this.array[d] = ""
										if (this.array[d] == "") {
											this.array.splice(d, 1);
											d = d - 1;
										}
									}
								}
							}
							if (this.array.length == 0) {
								this.price = 0
								this.chooseall = 0
								this.click = true
								return
							}
							this.calculate_cart()
						} else {
							var arrayc = []
							for (var j = 0; j < this.mycar[index].goods.length; j++) {
								this.mycar[index].goods[j].ischoose = 1
								this.array.push(this.mycar[index].goods[j].goods_id)
							}
							this.mycar[index].ischoose = 1
							this.clickb = true
							this.clicka = true
							this.mycar.push(newproject)
							this.mycar.pop()
							this.calculate_cart()
							for (var i = 0; i < this.mycar.length; i++) {
								for (var j = 0; j < this.mycar[i].goods.length; j++) {
									arrayc.push(this.mycar[i].goods[j].goods_id)
								}
							}
							if (arrayc.length == this.array.length) {
								this.chooseall = 1
								this.click = false
							}
						}
					}
				}
			},
			tochooseone(index, indexa, id) {
				var newproject = {}
				for (var i = 0; i < this.mycar[index].goods.length; i++) {
					if (id == this.mycar[index].goods[i].goods_id) {
						if (this.clickb) {
							this.clickb = false
							this.mycar[index].goods[indexa].ischoose = 0
							for (var i = 0; i < this.array.length; i++) {
								if (this.array[i] == id) {
									this.array[i] = ""
									if (this.array[i] == "") {
										this.array.splice(i, 1);
										i = i - 1;
									}
								}
							}
							this.mycar[index].goods.push(newproject)
							this.mycar[index].goods.pop()
							if (this.array.length == 0) {
								this.price = 0
								return
							} else {
								this.calculate_cart()
							}
						} else {
							this.clickb = true
							this.mycar[index].goods[indexa].ischoose = 1
							this.array.push(this.mycar[index].goods[indexa].goods_id)
							this.mycar[index].goods.push(newproject)
							this.mycar[index].goods.pop()
							this.calculate_cart()
						}
					}
				}
			},
			addcart(e) {
				this.$post("cart/addcart", {
					goods_id: e
				}).then(response => {
					if (response.error == 0) {} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			delcart(e) {
				this.$post("cart/delcart/", {
					cart_ids: e,
				}).then(response => {
					if (response.error == 0) {
						this.$message.success(response.msg)
						this.mycart()
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			mycart(e) {
				this.$post("cart/mycart", {}).then(response => {
					if (response.error == 0) {
						this.mycar = response.data
						this.mycarnum = response.num
						localStorage.setItem("buycarcount",response.cart_count)
						for (var i = 0; i < response.data.length; i++) {
							this.mycar[i].ischoose = 0
							for (var j = 0; j < response.data[i].goods.length; j++) {
								this.mycar[i].goods[j].ischoose = 0
							}
						}
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			toflow(e, b) {
				this.$router.push({
					path: '/payConfirm',
					query: {
						id: b,
						data: e
					}
				})
				this.array = []
			},
			calculate_cart() {
				this.$post("cart/calculate_cart", {
					goods_ids: this.array
				}).then(response => {
					if (response.error == 0) {
						this.price = response.price
					} else {
						return
					}
				});
			},
			checkout() {
				if (this.array.length == 0) {
					this.$message.success("请选择商品")
					return
				}
				this.$post("order/checkout", {
					goods_ids: this.array
				}).then(response => {
					if (response.error == 0) {
						this.toflow(response.data, this.array)
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
		},
	};
</script>

<style>
	.p-buycar {
		width: 74%;
		margin: 0 auto;
		min-height: 488px;
	}

	.p-buycar-head {
		height: 54px;
		width: 100%;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 54px;
		background: #FFFFFF;
		background: url(../../../assets/img/buycar/buycarhead.png);
	}

	.p-buycar-tips {
		margin-left: 20px;
		height: 60px;
		font-size: 14px;
		line-height: 60px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
	}

	.p-buycar-text {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		text-align: left;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		color: #333333;
	}

	.p-buycar-three {
		height: auto;
		background: #FFFFFF;
		margin-top: 20px;
		overflow: hidden;
	}

	.p-buycar-three-item {
		height: 126px;
		width: 98%;
		margin-left: 1%;
		background: #FFFFFF;
		margin-top: 20px;
	}

	.p-buycar-b {
		border-bottom: 1px solid #E5E5E5;
	}

	.p--buycar-item-head {
		height: 50px;
		width: 100%;
		line-height: 50px;
		font-size: 14px;
	}

	.p-classabout {
		width: 14px;
		height: 15px;
		margin-top: auto;
		margin-bottom: auto;
	}

	.p--buycar-more {
		width: 5px;
		height: 11px;
		margin-left: 11px;
		margin-top: auto;
		margin-bottom: auto;
	}

	.p-buycar-image {
		width: 100px;
		height: 100px;
		border-radius: 10px;
		float: left;
	}

	.p-buycar-radio {
		float: left;
		margin-top: 55px;
	}

	.p-buycar-delete {
		width: 88px;
		height: 24px;
		background: #00D0B0;
		border-radius: 5px;
		font-size: 12px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 24px;
		cursor: pointer;
		text-align: center;
	}

	.p-buycar-bottom {
		width: 100%;
		height: 64px;
		background: #FFFFFF;
		margin-top: 30px;

	}

	.p-buycar-count {
		width: 140px;
		height: 64px;
		background: #E6E6E6;
		text-align: center;
		line-height: 64px;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
		cursor: pointer;
		float: right;
	}

	.p-buycar-count-c {
		background: #00D0B0;
		color: #FFFFFF;
	}

	.p-buycar-price {
		font-size: 14px;
		float: right;
		text-align: center;
	}

	.p-buycar-price span {
		font-size: 20px !important;
		color: #F83850;
		line-height: 36px;
	}

	.p-buycar-price :nth-child(2) {
		font-size: 12px;
		color: #F83850;
	}

	.l64 {
		line-height: 64px;
	}

	.p-radio {
		width: 17px;
		height: 17px;
		border: 1px solid #CCCCCC;
		border-radius: 50%;
		margin-top: auto;
		margin-bottom: auto;
		margin-left: 21px;
		margin-right: 21px;
	}

	.p-radio-c {
		border: none;
		border: 1px solid #FFFFFF;
		background: #00D0B0;
	}

	.ml-2 {
		margin-left: -2px;
	}

	.p-classabout-span {
		width: 100px;
		height: 54px;
		line-height: 54px;
		margin-left: 2px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
</style>
